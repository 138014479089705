import axios from "axios";
let serverUrl = "";
// serverUrl = "http://localhost:3000";
serverUrl = `${process.env.GATSBY_SERVER_API_URL}`;

export const getShopMetafields = async () => {
  let shopMFs = null;

  try {
    shopMFs = await axios.get(`${serverUrl}/api/metafields/shop`);
  } catch (e) {
    console.log('getShopMetafields error', e);
  }

  return shopMFs;
};
export const getCollectionMetafields = async (coll_handle) => {
  let collMFs = null;

  try {
    collMFs = await axios.get(
      `${serverUrl}/api/metafields/collections?coll_handle=${coll_handle}`
    );
  } catch (e) {
    console.log('getCollectionMetafields error', e);
  }

  return collMFs;
};
