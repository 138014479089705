import React from 'react';
import Image from 'gatsby-image';
import { getImage } from '~/helpers';
import { BackgroundDiv } from '~/components/.base/containers';
import './ProductGrid.scss';
import ProductItem from './components';

const ProductGrid = ({ products, bgImages, title, handle }) => {
  return (
    <>
      <BackgroundDiv width="50%" left="0" bottom="0" z="-1">
        <Image fluid={getImage(bgImages, 'blurred_smudge_3')} alt="" />
      </BackgroundDiv>
      {products ? (
        <ul className="product-grid">
          {products.map(
            (
              {
                title,
                handle,
                images,
                variants,
                collectionHasDiscount,
                productHasDiscount,
                shopifyId,
              },
              i
            ) => (
              <ProductItem
                key={i}
                title={title}
                handle={handle}
                images={images}
                variants={variants}
                collectionHasDiscount={collectionHasDiscount}
                productHasDiscount={productHasDiscount}
                shopifyId={shopifyId}
              />
            )
          )}
        </ul>
      ) : (
        <div className="product-grid">
          <p>No products found!</p>
        </div>
      )}
    </>
  );
};

export default ProductGrid;
