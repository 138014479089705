import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FlexBox } from 'components/.base/containers';
import { BodyTextAlt, Heading } from 'components/.base/headings';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import styled from 'styled-components';

import TagIcon from '~/images/icons/tag.svg';
import StoreContext from 'layouts/StoreContext';
import { getQuantityDiscounts } from 'helpers/requests/quantity-discount-requests';

const DiscountCode = styled.div`
  background-color: rgba(212, 33, 45, 0.2);
  width: fit-content;
  padding: 5px;
  margin: 0 0 15px;
  display: flex;
  align-items: center;
  margin: auto;
  p {
    margin: 0;
  }
  svg {
    width: 17px;
    margin-right: 5px;
    fill: #555;
  }
`;

const ProductItem = ({
  title,
  handle,
  images,
  variants,
  collectionHasDiscount,
  productHasDiscount,
  shopifyId,
}) => {
  const [quantityDiscounts, setQuantityDiscounts] = useState([]);
  const [quantity] = useState(handle === 'live-lobsters-minimum-2' ? 2 : 1);
  const [activeQuantityDiscount, setActiveQuantityDiscount] = useState(null);
  // const [isAGiftCard] = useState(variants?.[0]?.sku?.includes('GIFT'));
  const [isAGiftCard] = useState(false);

  const { store } = useContext(StoreContext);
  const {
    checkout: { discountApplications },
    discountType,
    discountAmount,
    linkDiscountCode,
  } = { ...store };

  // STEP 1. Handle whether product has quantity discounts
  useEffect(() => {
    if (shopifyId) {
      getQuantityDiscounts(shopifyId)
        .then(res => {
          setQuantityDiscounts(res.data);
        })
        .catch(err => console.log(err));
    }
  }, [shopifyId]);

  // STEP 2. Handle whether first variant has quantity discounts
  useEffect(() => {
    for (let discount of quantityDiscounts) {
      if (discount.shopify_variant_id === variants[0].shopifyId) {
        if (quantity >= discount.quantity_threshold) {
          setActiveQuantityDiscount(discount);
          return;
        }
      }
    }
    setActiveQuantityDiscount(0);
  }, [quantity, quantityDiscounts, shopifyId]);

  // STEP 3. Calculate total discount
  const discountedAmount = useMemo(() => {
    let amount = 0;

    if (discountType === 'Percentage') {
      if (activeQuantityDiscount) {
        amount += (Number(discountAmount) / 100) * activeQuantityDiscount.price_per_unit * quantity;
      } else {
        amount += (Number(discountAmount) / 100) * Number(variants[0].price.amount) * quantity;
      }
    } else if (discountType === 'Absolute') {
      amount += discountAmount;
    }

    return amount;
  }, [isAGiftCard, discountType, discountAmount, activeQuantityDiscount, quantity, variants]);

  return (
    <li role="group" aria-label="product">
      <Link to={`/products/${handle}/`} state={{ sourceCollection: { title, handle } }}>
        <FlexBox direction="column" justify="space-between" align="center" className="product-tile">
          <div className="img-cont">
            {images?.[0]?.localFile?.childImageSharp?.fluid ? (
              <Image fluid={images[0].localFile.childImageSharp.fluid} alt="" />
            ) : (
              <img src={no_image} alt="" />
            )}
          </div>
          <div className="details">
            <BodyTextAlt className="product-heading" as="h3">
              {title}
            </BodyTextAlt>
            {activeQuantityDiscount ? (
              <FlexBox justify="center">
                <Heading as="p">
                  <span className="red">
                    <span class="sr-only">Current price:</span>
                    <span className="dollar-sign">$</span>
                    {(activeQuantityDiscount.price_per_unit * quantity - discountedAmount).toFixed(
                      2
                    )}
                  </span>
                </Heading>
                <Heading as="p" className="compare-at-price">
                  <span>
                    <span class="sr-only">Original price:</span>
                    <span className="dollar-sign">$</span>
                    <strike>{(Number(variants[0].price.amount) * quantity).toFixed(2)}</strike>
                  </span>
                </Heading>
              </FlexBox>
            ) : (
              <FlexBox justify="center">
                <Heading as="p">
                  <span className="red a">
                    <span class="sr-only">Current price:</span>
                    <span className="dollar-sign">$</span>
                    {(
                      Number(variants[0].price.amount) * quantity -
                      (collectionHasDiscount || productHasDiscount ? discountedAmount : 0)
                    ).toFixed(2)}
                  </span>
                </Heading>
                {discountedAmount !== 0 && (collectionHasDiscount || productHasDiscount) && (
                  <Heading as="p" className="compare-at-price">
                    <span>
                      <span class="sr-only">Original price:</span>
                      <span className="dollar-sign">$</span>
                      <strike>{Number(variants[0].price.amount).toFixed(2)}</strike>
                    </span>
                  </Heading>
                )}
              </FlexBox>
            )}
            {discountedAmount !== 0 && (collectionHasDiscount || productHasDiscount) && (
              <DiscountCode>
                <TagIcon />
                <BodyTextAlt color="#d4212d" md="0">
                  {discountApplications?.length ? discountApplications[0].code : linkDiscountCode}:
                  -$
                  {Number(discountedAmount).toFixed(2)}
                </BodyTextAlt>
              </DiscountCode>
            )}
          </div>
        </FlexBox>
      </Link>
    </li>
  );
};

export default ProductItem;
