import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { useMediaQuery } from 'react-responsive';
import { css } from 'styled-components';

import { size } from '~/constants/Breakpoint';
import { getImage, getImageWidth, getMetafield } from '~/helpers';
import { getCollectionMetafields } from '~/helpers/requests/metafield-requests';
import { HeadingLarge, Heading } from '~/components/.base/headings';
import { FlexBox, BackgroundDiv } from '~/components/.base/containers';
import { WhiteButton } from '~/components/.base/buttons';
import './CollectionPageFooter.scss';

const CollectionPageFooter = ({ collectionImages, collectionHandle }) => {
    const isMobile = useMediaQuery({ query: `(max-width: ${size.lg - 1}px)` });

    const [relatedCollOne, setRelatedCollOne] = useState(null);
    const [relatedCollTwo, setRelatedCollTwo] = useState(null);
    useEffect(() => {
        getCollectionMetafields(collectionHandle)
            .then(res => {
                if (res.data && res.data.length) {
                    const mfData = res.data.map(item => item.node);

                    if (getMetafield(mfData, 'related_collection_1')) {
                        setRelatedCollOne({
                            title: getMetafield(mfData, 'related_collection_1_title'),
                            handle: getMetafield(mfData, 'related_collection_1'),
                            image: getMetafield(mfData, 'related_collection_1_image')
                                ? JSON.parse(getMetafield(mfData, 'related_collection_1_image'))[0]
                                : false,
                        });
                    }
                    if (getMetafield(mfData, 'related_collection_2')) {
                        setRelatedCollTwo({
                            title: getMetafield(mfData, 'related_collection_2_title'),
                            handle: getMetafield(mfData, 'related_collection_2'),
                            image: getMetafield(mfData, 'related_collection_2_image')
                                ? JSON.parse(getMetafield(mfData, 'related_collection_2_image'))[0]
                                : false,
                        });
                    }
                }
            })
            .catch(err => console.log(err));
    }, []);
    return (
        <>
            <div className="collection-page-footer">
                <HeadingLarge as="h2" style={{ textAlign: 'center' }}>
                    Continue{' '}
                    <span className="red">
                        your
                        <br />
                        journey
                    </span>
                </HeadingLarge>
                {isMobile ? (
                    <div className="mobile-footer">
                        <div className="related-collections">
                            {relatedCollOne ? (
                                <Link to={`/collections/${relatedCollOne.handle}`}>
                                    <div className="related-col-tile">
                                        {relatedCollOne.image ? (
                                            <img
                                                src={`${relatedCollOne.image.cloudinary_src}w_400`}
                                                alt=""
                                            />
                                        ) : (
                                            <Image
                                                fluid={getImage(collectionImages, 'lobster')}
                                                alt=""
                                            />
                                        )}

                                        <WhiteButton as="span" className="related-col-tile-plus" aria-label="View more">
                                            <span aria-hidden="true">+</span>
                                        </WhiteButton>
                                        <Heading md="1rem auto 2rem">
                                            {relatedCollOne.title}
                                        </Heading>
                                    </div>
                                </Link>
                            ) : (
                                <Link to="/collections/lobster">
                                    <div className="related-col-tile">
                                        <Image
                                            fluid={getImage(collectionImages, 'lobster')}
                                            alt=""
                                        />
                                        <WhiteButton as="span" className="related-col-tile-plus" aria-label="View more">
                                            <span aria-hidden="true">+</span>
                                        </WhiteButton>
                                        <Heading md="1rem auto 2rem">Lobster</Heading>
                                    </div>
                                </Link>
                            )}
                            {relatedCollTwo ? (
                                <Link to={`/collections/${relatedCollTwo.handle}`}>
                                    <div className="related-col-tile">
                                        {relatedCollTwo.image ? (
                                            <img
                                                src={`${relatedCollTwo.image.cloudinary_src}w_400`}
                                                alt=""
                                            />
                                        ) : (
                                            <Image
                                                fluid={getImage(collectionImages, 'family-meals')}
                                                alt=""
                                            />
                                        )}
                                        <WhiteButton as="span" className="related-col-tile-plus" aria-label="View more">
                                            <span aria-hidden="true">+</span>
                                        </WhiteButton>
                                        <Heading md="1rem auto 2rem">
                                            {relatedCollTwo.title}
                                        </Heading>
                                    </div>
                                </Link>
                            ) : (
                                <Link to="/collections/entrees-sides">
                                    <div className="related-col-tile">
                                        <Image
                                            fluid={getImage(collectionImages, 'family-meals')}
                                            alt=""
                                        />
                                        <WhiteButton as="span" className="related-col-tile-plus" aria-label="View more">
                                            <span aria-hidden="true">+</span>
                                        </WhiteButton>
                                        <Heading md="1rem auto 2rem">Entrees & sides</Heading>
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div>
                ) : (
                    <>
                        <BackgroundDiv
                            left="0"
                            top="-100px"
                            z="1"
                            css={css`
                                .gatsby-image-wrapper {
                                    width: ${getImageWidth(collectionImages, 'blurry_lobster')}px;
                                }
                            `}
                        >
                            <Image fluid={getImage(collectionImages, 'blurry_lobster')} alt="" />
                        </BackgroundDiv>
                        <BackgroundDiv
                            right="0"
                            bottom="0"
                            z="1"
                            css={css`
                                .gatsby-image-wrapper {
                                    width: ${getImageWidth(collectionImages, 'corner_lobster')}px;
                                }
                            `}
                        >
                            <Image
                                fluid={getImage(collectionImages, 'corner_lobster')}
                                alt=""
                                className="corner-lobster"
                            />
                        </BackgroundDiv>
                        <FlexBox justify="center" className="related-collections">
                            {relatedCollOne ? (
                                <Link to={`/collections/${relatedCollOne.handle}`}>
                                    <div className="related-col-tile">
                                        {relatedCollOne.image ? (
                                            <img
                                                src={`${relatedCollOne.image.cloudinary_src}w_600`}
                                                alt=""
                                            />
                                        ) : (
                                            <Image
                                                fluid={getImage(
                                                    collectionImages,
                                                    'lobster_sandwich'
                                                )}
                                                alt=""
                                            />
                                        )}

                                        <WhiteButton as="span" className="related-col-tile-plus" aria-label="View more">
                                            <span aria-hidden="true">+</span>
                                        </WhiteButton>
                                        <Heading md="1rem auto 2rem">
                                            {relatedCollOne.title}
                                        </Heading>
                                    </div>
                                </Link>
                            ) : (
                                <Link to="/collections/lobster">
                                    <div className="related-col-tile">
                                        <Image
                                            fluid={getImage(collectionImages, 'lobster_sandwich')}
                                            alt=""
                                        />
                                        <WhiteButton as="span" className="related-col-tile-plus" aria-label="View more">
                                            <span aria-hidden="true">+</span>
                                        </WhiteButton>
                                        <Heading md="1rem auto 2rem">Lobster</Heading>
                                    </div>
                                </Link>
                            )}
                            {relatedCollTwo ? (
                                <Link to={`/collections/${relatedCollTwo.handle}`}>
                                    <div className="related-col-tile">
                                        {relatedCollTwo.image ? (
                                            <img
                                                src={`${relatedCollTwo.image.cloudinary_src}w_600`}
                                                alt=""
                                            />
                                        ) : (
                                            <Image
                                                fluid={getImage(
                                                    collectionImages,
                                                    'girl_eating_lobster'
                                                )}
                                                alt=""
                                            />
                                        )}
                                        <WhiteButton as="span" className="related-col-tile-plus" aria-label="View more">
                                            <span aria-hidden="true">+</span>
                                        </WhiteButton>
                                        <Heading md="1rem auto 2rem">
                                            {relatedCollTwo.title}
                                        </Heading>
                                    </div>
                                </Link>
                            ) : (
                                <Link to="/collections/entrees-sides">
                                    <div className="related-col-tile">
                                        <Image
                                            fluid={getImage(
                                                collectionImages,
                                                'girl_eating_lobster'
                                            )}
                                            alt=""
                                        />
                                        <WhiteButton as="span" className="related-col-tile-plus" aria-label="View more">
                                            <span aria-hidden="true">+</span>
                                        </WhiteButton>
                                        <Heading md="1rem auto 2rem">Entrees & sides</Heading>
                                    </div>
                                </Link>
                            )}
                        </FlexBox>
                    </>
                )}
            </div>
        </>
    );
};

export default CollectionPageFooter;
