import React, { useState, useContext, useEffect } from "react";
import StoreContext from "~/layouts/StoreContext";
import { graphql } from "gatsby";
import styled from "styled-components";

import { Criteo } from "~/components/Criteo/Criteo";
import SEO from "~/components/seo";
import { DeriveHeaderClasses } from "~/components/Navigation";
import { ProductGrid, CollectionPageFooter } from "~/components/CollectionPage";
import MailingListPopup from "~/components/JoinMailingList/MailingListPopup";
import { BodyText } from "~/components/.base/headings";
import Hero from "~/components/.base/hero";

import { breakpoint } from "../../constants/Breakpoint";
import { verifyDiscountCode } from "~/helpers/requests/discount-code-requests";

const Template = styled.div`
  position: relative;
  .hero {
    min-height: 600px;
    max-height: 80vh;
  }
  .header-details {
    position: absolute;
    z-index: 3;
    margin-bottom: 40px;
    text-align: center;
    h1,
    h3,
    p {
      margin: auto;
      text-align: center;
      max-width: 600px;
    }
    h1 {
      margin-bottom: 1.5rem;
    }
  }
  @media ${breakpoint.maxMd} {
    .header-details {
      width: 90%;
      position: relative;
      margin: 0;
      padding: calc(var(--header-height) + 40px) 0;
    }
    .hero {
      min-height: 0px;
      max-height: 70vh;
    }
  }
`;

const CollectionLabel = styled.h1`
  display: block;
  margin: ${props => props.md};
  margin-bottom: auto !important;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${props => props.color || '#5c6566'};
  .red {
    color: #d4212c;
  }
`;

const CollectionName = styled.h2`
  margin: ${props => props.md};
  margin-bottom: 1.5rem !important;
  text-align: ${props => props.align};
  font-family: 'Tiempos Text', serif;
  font-size: 48px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.92;
  letter-spacing: normal;
  color: #2e3233;
  color: ${props => props.color};
  text-transform: uppercase;
  .white {
    color: #fff;
  }
  .red {
    color: #d4212c;
  }
  .indent {
    margin-left: 5rem;
  }
  @media screen and (max-width: 767px) {
    font-size: 36px;
    letter-spacing: 0.5px;
    .indent {
      margin-left: 0;
    }
  }
`;

const CollectionPage = ({ data, location }) => {
  const {
    title,
    description,
    handle,
    products,
    image
  } = data.shopifyCollection;
  const {
    store: { collectionHandlers, productHandlers, AllDiscountItems, linkDiscountCode }
  } = useContext(StoreContext);
  const [collectionHasDiscount, setCollectionDiscount] = useState(false);
  const productWithDiscount = products.map(product => ({
    ...product,
    productHasDiscount: ((productHandlers && productHandlers.includes(product.handle)) ? true : false) || AllDiscountItems ? true : false,
    collectionHasDiscount: ((collectionHandlers && collectionHandlers.includes(handle)) ? true : false) || collectionHasDiscount,
  }));

  const filteredProducts = productWithDiscount.filter(
    (p) => !p.title.includes("Subscription")
  );
  let metaData;

  if (image) {
    metaData = [
      {
        property: `og:image`,
        content: image.localFile.childImageSharp.fluid.src
      }
    ];
  }
  const [collectionProductIds, setCollectionProductIds] = useState(null);

  useEffect(() => {
    (async () => {

      if (collectionHandlers?.length && handle && linkDiscountCode) {
        setCollectionDiscount(collectionHandlers.includes(handle));
      }
      let arr = [];
      for (let product of products) {
        for (let variant of product.variants) {
          const productId = product.shopifyId.replace(
            "gid://shopify/Product/",
            ""
          );
          const varId = variant.shopifyId.replace(
            "gid://shopify/ProductVariant/",
            ""
          );
          arr.push(`shopify_us_${productId}_${varId}`);
        }
      }
      setCollectionProductIds(arr.slice(0, 3));
    })()
  }, [products, handle, AllDiscountItems, linkDiscountCode, collectionHandlers]);

  return (
    <Template>
      <MailingListPopup />
      <SEO title={title} description={description} meta={metaData} />
      <Criteo type="collection" collectionProductIds={collectionProductIds} />
      <DeriveHeaderClasses location={location} />
      <Hero
        wrapperClass="hero"
        imageFluid={image.localFile.childImageSharp.fluid}
        alt={title}
      >
        <div className="header-details">
          <CollectionLabel color="#fff">Collection</CollectionLabel>
          <CollectionName color="#fff">{title}</CollectionName>
          <BodyText color="#fff">{description}</BodyText>
        </div>
      </Hero>
      <ProductGrid
        bgImages={data.bgImages.edges}
        products={filteredProducts}
        title={title}
        handle={handle}
      />
      <CollectionPageFooter
        collectionImages={data.collectionImages.edges}
        collectionHandle={handle}
      />
    </Template>
  );
};
export const query = graphql`
  query($handle: String!) {
    shopifyCollection(handle: { eq: $handle }) {
      id
      title
      handle
      description
      image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      products {
        title
        shopifyId
        images {
          originalSrc
          id
          localFile {
            childImageSharp {
              fluid(maxWidth: 910) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        handle
        variants {
          shopifyId
          price {
            amount
            currencyCode
          }
        }
      }
    }
    collectionImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: { regex: "/images/collection/" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp
            }
            original {
              width
            }
          }
        }
      }
    }
    bgImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: { regex: "/images/background/" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default CollectionPage;
